.get-in-touch {
    background: transparent linear-gradient(0deg, #F8F8F800 0%, #DCDCDC4F 9%, #DEDEDE6B 40%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    margin-top: 5%;
    margin-bottom: 7%;
}

.get-in-touch .image-holder {
    position: relative;
}

.get-in-touch .image-box-holder .getin-img {
    width: 100%;
}

.get-in-touch .image-holder .overlay-color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent linear-gradient(270deg, #F8AA0500 0%, #E31E24B3 100%) 0% 0% no-repeat padding-box;
    border-radius: 13px;
    z-index: 1;
}

.get-in-touch .touch-country {
    display: flex;
    margin-top: 22px;

}

.get-in-touch .touch-country .circle-box {
    position: relative;
    margin-right: 31px;
}

.get-in-touch .touch-country .bg-img {
    width: 45px;
    height: 45px;
}

.get-in-touch .touch-country .icon-img {
    position: absolute;
    top: 0px;
    right: -15px;
    z-index: 1;
}

.get-in-touch .touch-country .icon-img .get-icon {
    width: 37px;
    height: 37px;
    object-fit: contain;
}

.get-in-touch .touch-country .text-heading p {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0%;
}

.get-in-touch .main-box {
    /* margin-top: 13%; */
    margin-left: 12%;
}

.get-in-touch .get-touch-box .whie-boxx {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    display: flex;
    padding: 18px;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: absolute;
    bottom: 33px;
    left: 31%;
}

.get-in-touch .whie-boxx .text-holder {
    margin-right: 29px;
}

.get-in-touch .whie-boxx .text-holder h2 {
    font-size: 30px;
    font-weight: bold;
    color: #E31E24;
    margin-bottom: 0%;
}

.get-in-touch .whie-boxx .text-holder h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0%;
}

.get-in-touch .get-touch-box {
    position: relative;
}

.get-in-touch .heading-name h3 {
    color: #202020;
    font-size: 60px;
    font-weight: bold;
    opacity: 0.1;
}

@media(min-width:0px) and (max-width:767px) {
    .get-in-touch .get-touch-box .whie-boxx {
        position: unset;
        margin-top: 30px;
        display: unset;
        background: transparent;
        box-shadow: none;
        border-radius: 15px;
        padding: 0px;
    }

    .get-in-touch .get-touch-box {
        position: unset;
    }

    .get-in-touch .button-holder {
        margin-top: 15px;
    }

    .get-in-touch .get-touch-box .bg-color {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        padding: 18px;
        margin-top: 12px;
    }

    .get-in-touch .whie-boxx .text-holder h2 {
        font-size: 15px;
    }

    .get-in-touch .whie-boxx .text-holder h3 {
        font-size: 14px;

    }

    .get-in-touch .touch-country .text-heading p {
        font-size: 15px;
        margin-bottom: 0px;
        margin-top: 11px;
    }

    .get-in-touch .touch-country {
        margin-top: 9px;
    }

    .get-in-touch .touch-country .icon-img .get-icon {
        width: 34px;
        height: 34px;
        object-fit: contain;
    }

    .get-in-touch .touch-country .bg-img {
        width: 40px;
        height: 40px;
    }

    .get-in-touch .main-box {
        margin-top: 5%;

    }

    .get-in-touch .heading-name h3 {
        font-size: 42px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    /* .get-in-touch .get-touch-box .whie-boxx {
        bottom: 15px;
        left: 19%;
        padding: 13px;
    } */

    /* .get-in-touch .main-box {
        margin-top: 0%;

    } */

    .get-in-touch .whie-boxx .text-holder h2 {
        font-size: 20px;
    }

    .get-in-touch .whie-boxx .text-holder h3 {
        font-size: 15px;

    }

    .get-in-touch .touch-country .text-heading p {
        font-size: 15px;
        margin-bottom: 0px;
        margin-top: 11px;
    }

    .get-in-touch .touch-country {
        margin-top: 9px;
    }

    .get-in-touch .touch-country .icon-img .get-icon {
        width: 34px;
        height: 34px;
        object-fit: contain;
    }

    .get-in-touch .touch-country .bg-img {
        width: 40px;
        height: 40px;
    }

    .get-in-touch .heading-name h3 {
        font-size: 47px;
    }

    .get-in-touch .get-touch-box .whie-boxx {
        position: unset;
        margin-top: 7px;
        display: flex;
        background-color: transparent;
        box-shadow: none;
        border-radius: 15px;
        padding: 0px;
    }

    .get-in-touch .get-touch-box .bg-color {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        padding: 18px;
        margin-top: 12px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .get-in-touch .get-touch-box .whie-boxx {
        bottom: 0px;
        left: 2%;
    }


    /* .get-in-touch .main-box {
        margin-top: 5%;

    } */

    .get-in-touch .whie-boxx .text-holder h2 {
        font-size: 27px;
    }

    .get-in-touch .whie-boxx .text-holder h3 {
        font-size: 21px;

    }

    

    
}

@media(min-width:992px) and (max-width:1400px) {
    .get-in-touch .touch-country {
        margin-top: 9px;
    }
}