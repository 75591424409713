.main-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1024;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

.main-header .headlogo {
    width: 97px;
    object-fit: contain;
}

.main-header .navbar-nav .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin: 0px 15px;

}


.main-header .navbar-nav .nav-link.active {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin: 0px 15px;
}

.main-header .nav-link-with-borders {
    position: relative;
}

.main-header .nav-link-with-borders.active .white-border {
    border-left: 2px solid #EB5717;

}

.main-header .white-border {
    position: absolute;
    height: 23px;
    top: 8px;
    left: 3%;

}

.main-header .nav-link-with-borders.active .white-border-active {
    border-left: 2px solid #EB5717;

}

.main-header .white-border-active {
    position: absolute;
    height: 23px;
    top: -3px;
    left: -9%;

}

.main-header .navbar-nav .dropdown-menu {
    top: 64px;
    border: none;
    background-color: #FFF !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
    background: transparent;
}

.main-header .dropdown-item {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    /* background: transparent linear-gradient( to right, 270deg, #F8AA05 0%, #ED6115 100%) 0% 0% no-repeat padding-box; */
    background: linear-gradient(to right, #ED6115 0%, #F8AA05 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.3s ease-in-out, color 0.3s ease-in-out;
}

.main-header .dropdown-item:hover {
    background-position: left bottom;
    color: #FFF;
    font-size: 15px;
    padding: 10px;
    font-weight: 500;
}


@media(min-width:0px) and (max-width:992px) {
    .main-header .white-border {
        left: -2%;

    }

    .main-header .navbar-nav .nav-link {
        font-size: 14px;
    }

    .main-header .navbar-nav .nav-link.active {
        font-size: 14px;
    }

    .main-header .navbar-toggler:focus {
        box-shadow: none;
    }

    .main-header .navbar-toggler {
        background-color: #000 !important;
        margin-left: auto;
        width: 44px;
        height: 36px;
        padding: 0px;
    }

    .main-header .navbar-toggler-icon {
        filter: brightness(0) invert(1);
        width: 20px;
    }
}