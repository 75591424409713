.contact-us {
    position: relative;
    /* margin-bottom: 12%; */
    overflow-x: hidden;
    overflow-y: hidden;
}

.contact-us .gmap_canvas .gmap_iframe {
    z-index: 0 !important;
    position: relative !important;
}

.contact-us .contact_form_holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: #fff;
}

.contact-us .contact_form_bg {
    background: none;
    width: 50%;
    right: 0px !important;
    display: flex;
    justify-content: end !important;
    left: 50%;
}

.contact-us .form-holder {
    padding: 30px 30px 0px 30px;
    background: #FFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 24px #0000002B;
    border-radius: 6px;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 91%, 91% 91%, 35% 91%, 33% 100%, 0% 100%);
}

.contact-us .form-holder .form_container {}

.contact-us .form-holder .form_container::-webkit-scrollbar {
    width: 2px;
}

.contact-us .form-holder .form_container::-webkit-scrollbar:horizontal {
    height: 8px;
}

/* Track */
.contact-us .form-holder .form_container::-webkit-scrollbar-track {
    background: #e8e8e8;
}

.contact-us .form-holder .form_container::-webkit-scrollbar-track:horizontal {
    background: #e8e8e8;
}

/* Handle */
.contact-us .form-holder .form_container::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    border-radius: 5px;
}

.contact-us .form-holder .form_container .scroller::-webkit-scrollbar-thumb:horizontal {
    background: #c7c7c7;
    border-radius: 5px;
}

.contact-us .heading-holder h2 {
    font-size: 50px;
    font-weight: 900;
    color: #000;
}


.contact-us .address-holder p {
    font-size: 15px;
    color: #000;
}

.contact-us .address-holder:hover p {
    color: #ED6115;
}

.contact-us .map-text p {
    text-decoration: underline;
    cursor: pointer;
    color: #707070;

}

.contact-us .form-holder .left-arrow {
    width: 38px;
    height: 31px;
    position: absolute;
    top: 41%;
    left: -23px;
    cursor: pointer;
}

.contact-us .form-holder .form-control {
    background: #f6f6f6;
    border-radius: 5px;
    font-size: 12px;
    border: none;
    color: #7b7b7b;
    margin-bottom: 8px;
}

.contact-us .text-holder-area {
    height: auto;
}

.contact-us .form-holder .form-control:focus {
    box-shadow: none;
}

.contact-us .border-holder {
    border-bottom: 2px solid #70707080;
}

.map-section .mapouter {
    text-align: center;
}

.map-section .gmap_iframe {
    width: 936px;
    height: 462px;
}


.contact-us .scale-down-hor-right {
    -webkit-animation: scale-down-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-down-hor-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    position: absolute;
    right: -78px;

}

.contact-us .scale-down-hor-right .btn-holder {
    padding-bottom: 40px;
}

.contact-us .scale-down-hor-right .map-text {
    display: none;
}

.contact-us .scale-down-hor-right .form-holder {
    clip-path: unset;
}

.contact-us .bg-color {
    background: linear-gradient(180deg, #f8e00530 0%, #e31e2438 100%);
    border-radius: 50% 0% 50% 50%;
    aspect-ratio: 1 / 0.9;
    padding: 53px 16px 0px 61px;
}

.contact-us .text-holder1 {
    padding-top: 15%;
}

.contact-us .text-holder1 h2 {
    font-size: 25px;
    font-weight: bold;
}

.contact-us .text-holder1 p {
    font-size: 15px;
    font-weight: 500;
    color: #707070;
}

.address-section {
    margin: 5% 0;
}

.address-section .address-text h5 {
    color: #D9D9D9;
    font-size: 15px;
    transform: matrix(0, -1, 1, 0, 0, 0);
    margin-top: 22px;
}

.address-section .para-text p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0%;
}

.address-section .fa-icon {
    color: #ED6115;
}

.address-section .border-line {
    border-right: 1px solid #A4A4A4;
    height: 100%;
}

.contact-bg-color {
    filter: drop-shadow(-1px 6px 3px #0000002B);
}


.address-section span {
    display: none;
}


@-webkit-keyframes scale-down-hor-right {
    0% {
        -webkit-transform: scaleX(1.4);
        transform: scaleX(1.4);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

@keyframes scale-down-hor-right {
    0% {
        -webkit-transform: scaleX(1.4);
        transform: scaleX(1.4);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    100% {
        -webkit-transform: scaleX(0.8);
        transform: scaleX(0.8);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

.contact-us .scale-up-hor-right {
    -webkit-animation: scale-up-hor-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-right 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-hor-right {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

@keyframes scale-up-hor-right {
    0% {
        -webkit-transform: scaleX(0.4);
        transform: scaleX(0.4);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
}

@media (min-width: 0px) and (max-width: 360px) {
    .address-section {
        margin-top: 51%;
        margin-bottom: 12%;
    }
}

@media (min-width: 0px) and (max-width: 486px) {
    .contact-us {
        margin-bottom: 40%;
    }

    .contact-us .address-holder p {
        font-size: 12px;

    }

    .contact-us .form-holder {
        padding: 30px 13px 0px 11px;
    }

    .contact-us .form-holder .left-arrow {
        width: 30px;
    }

    .address-section .para-text p {
        font-size: 13px;

    }
}

@media (min-width: 486px) and (max-width: 767px) {
    .contact-us {
        margin-bottom: 20%;
    }

    .contact-us .address-holder p {
        font-size: 12px;

    }

    .contact-us .form-holder {
        padding: 30px 13px 0px 11px;
    }
}

@media (min-width: 0px) and (max-width: 767px) {
    .map-section .gmap_iframe {
        width: 90%;

    }

    .contact-us .text-holder1 {
        padding-top: 0%;
    }

    .contact-us .text-holder1 h2 {
        font-size: 15px;

    }

    .contact-us .text-holder1 p {
        font-size: 13px;

    }

    .contact-us .bg-color {
        padding: 34px 16px 0px 26px;
        background: transparent;
        aspect-ratio: unset;
    }

    .text-holder1 br {
        display: none;
    }


    .contact-us {
        margin-top: 11%;
        overflow-x: unset;
        overflow-y: unset;

    }

    .contact-us .scale-down-hor-right {
        animation: scale-down-hor-right 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        position: unset;

    }

    .address-section span {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        display: block;
    }

    .address-section .address-text h5 {
        color: #000;
        font-size: 15px;
        transform: unset;
        margin-top: 14px;
        display: none;
    }

    .address-section .border-line {
        border-right: none;

    }

    .address-section .fa-icon {
        margin-right: 8px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .map-section .gmap_iframe {
        width: 80%;

    }

    .contact-us .address-holder p {
        font-size: 12px;

    }

    .contact-us .text-holder1 h2 {
        font-size: 15px;

    }

    .contact-us .form-holder {
        padding: 30px 23px 0px 23px;
    }

    .contact-us .text-holder1 p {
        font-size: 13px;

    }

    .contact-us .text-holder1 {
        padding-top: 0%;
    }

    .text-holder1 br {
        display: none;
    }
}



@media (min-width: 992px) and (max-width: 1200px) {
    .contact-us .text-holder1 h2 {
        font-size: 18px;
    }

    .contact-us .text-holder1 p {
        font-size: 14px;
    }

    .contact-us .text-holder1 {
        padding-top: 3%;
    }

    .map-section .gmap_iframe {
        width: 80%;
    }

    .contact-us .form-holder {
        clip-path: polygon(0 0, 100% 0, 100% 94%, 94% 94%, 41% 94%, 38% 100%, 0% 100%);
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .contact-us .text-holder1 h2 {
        font-size: 18px;
    }

    .contact-us .text-holder1 p {
        font-size: 14px;
    }

    .contact-us .text-holder1 {
        padding-top: 10%;
    }
}