.footer .border-line {
    border-top: 0.5px solid #E31E24;
    margin-bottom: 85px;
}

.footer .header-logo {
    width: 171px;
    object-fit: contain;
}

.footer .quick-link h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 26px;
    font-family: "Montserrat";
}

.footer .quick-link .foot-menu {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
    font-family: "Montserrat";
}

.footer a {
    color: #000;
}

.footer .quick-link p {
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 500;
}

.footer .border-icon {
    background-color: #000;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    color: #FFF;
    margin-right: 18px;
}

.footer .fb-icon {
    margin-top: 2px;
    font-size: 14px;
}

/* .footer .social-icon {
    margin-top: 42px;
} */

.footer .text-holder {
    margin-top: 30px;
}

.footer .text-holder h5 {
    font-size: 15px;
    font-weight: 500;
    font-family: "Montserrat";
}

.footer .border-copyright {
    border: 1px solid #E31E24;
    width: 500px;
    margin: auto;
}

.footer .copyright-holder p {
    font-size: 12px;

}

@media(min-width:0px) and (max-width:992px) {
    .footer .border-line {
        margin-bottom: 5px;
    }

    .footer .quick-link h3 {
        font-size: 14px;
        margin-bottom: 9px;
        margin-top: 10px;

    }

    .footer .quick-link .foot-menu {
        font-size: 14px;
        margin-bottom: 4px;

    }

    .footer .text-holder h5 {
        font-size: 12px;

    }

    .footer .border-copyright {
        width: 290px;
    }

    .footer .header-logo {
        width: 132px;
    }

    /* .footer .social-icon {
        margin-top: 17px;
    } */

    /* .footer .logo-footer {
        text-align: center;
    } */
    .footer .quick-link p {
        font-size: 12px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .footer .border-icon {
        margin: auto;
    }

    .linkss {
        text-align: center;
    }
}