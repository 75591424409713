.product-page-card {
    margin-bottom: 5%;
    margin-top: 1%;
}

.product-page-card .dropdown-menu {
    border: none;
    background-color: #FFF !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0%;
    padding-top: 0%;
    padding-bottom: 0%;
    background: transparent;
}

.product-page-card .dropdown-item {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    /* background: transparent linear-gradient( to right, 270deg, #F8AA05 0%, #ED6115 100%) 0% 0% no-repeat padding-box; */
    background: linear-gradient(to right, #ED6115 0%, #F8AA05 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.3s ease-in-out, color 0.3s ease-in-out;
}

.product-page-card .dropdown-item:hover {
    background-position: left bottom;
    color: #FFF;
    font-size: 15px;
    padding: 10px;
    font-weight: 500;
}

.product-page-card .btn-secondary {
    background-color: transparent !important;
    border-radius: 0%;
    border-bottom: 1px solid #000;
    color: #000 !important;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 200px;
    text-align: left;
    padding-left: 0%;
    font-size: 15px;
    font-weight: 500;

}

.product-page-card .btn-secondary:hover {
    background-color: transparent !important;
    border-radius: 0%;
    border-bottom: 1px solid #000;
    color: #000;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 200px;
    text-align: left;
    padding-left: 0%;
    font-size: 15px;
    font-weight: 500;

}

.product-page-card .dropdown-toggle::after {
    float: right;
    margin-top: 9px;
}

.product-page-card .download-box {
    display: block;
}

.product-page-card a {
    color: #000;
}

.product-page-card .pagination {
    background-color: transparent !important;
    --bs-pagination-bg: transparent !important;
}

.product-page-card .page-item {
    margin-right: 5px;
    height: 30px;

}

.product-page-card .page-item .page-link {
    background: #CCCCCC;
    border-radius: 3px;
    box-shadow: none;
    color: #000;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 30px;

}


.product-page-card .pagination .page-item:first-child .page-link {
    border-radius: 27px 0 0 27px;
}


.product-page-card .pagination .page-item:last-child .page-link {
    border-radius: 0 27px 27px 0;
}


.product-page-card .pagination .page-item:first-child .page-link:hover {
    background: linear-gradient(180deg, #F8AA05 0%, #E31E24 100%);
    border-radius: 27px 0 0 27px;
    color: #FFF;

}


.product-page-card .pagination .page-item:last-child .page-link:hover {
    background: linear-gradient(180deg, #F8AA05 0%, #E31E24 100%);
    border-radius: 0 27px 27px 0;
    color: #FFF;

}

.product-page-card .page-link:active {
    background: #1A1A1A;
    border: 2px solid #000000;
    border-radius: 50px;
    background: #d2e3ff;
    height: 30px;
}

.product-page-card .button {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: transparent;
    color: black;
    transition: 0.3s;
    border: none;
    border-radius: 0;
    font-size: 14px;
}

.product-page-card .button:hover {
    background: linear-gradient(180deg, #F8AA05 0%, #E31E24 100%);
    border: none;
    color: #FFF;
    font-size: 14px;
    height: 30px;
    border-radius: 3px;
}

.product-page-card .button1 {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: transparent;
    color: #FFF;
    transition: 0.3s;
    border: none;
    border-radius: 0;
}

.product-page-card .button1:hover {
    color: #FFF;

}

.product-page-card .button1 .label-up {
    display: block;
    margin: 5px 20px;
    height: 100%;
    position: relative;
    top: 0%;
    transition: 0.3s;
}

.product-page-card .button1:hover .label-up {
    top: -112%;
}

.product-page-card .label-up {
    display: block;
    margin: 0px 20px;
    height: 100%;
    position: relative;
    top: 0%;
    transition: 0.3s;
}

.product-page-card .form-check-input[type=checkbox] {
    border-radius: 50px;
}