.product-category .card {
    background: linear-gradient(180deg, rgba(219, 222, 221, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(219, 222, 221, 1) 100%);
    border-radius: 15px 15px 28px 15px;
    border: none;
    position: relative;
    /* Ensure the card is positioned relative for absolute positioning of child elements */
    z-index: 1;
    /* Ensure the card has a lower z-index than the download box */
}

.product-category .card .product-icon {
    width: 100%;
    height: 233px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain !important;
    aspect-ratio: 1/1;
    background-position: center;
    overflow: hidden;
}

.product-category .card .product-img {
    width: 200px;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1/1;
    transition: transform 0.5s;
}

.product-category .card:hover .product-icon .product-img {
    transform: scale(1.1);
}

.product-category .card .card-body {
    background: #373736 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 28px 15px;
    position: relative;
    z-index: 1;

}

.product-page-card .card .card-body {
    padding-top: 0%;
    padding-bottom: 0%;
}

.product-category .card .card-title {
    font-size: 16px;
    font-weight: 500;
    color: #FFF;
    margin-bottom: 0px;
}

.product-page-card .card .card-title {
    padding-top: 9px;
    padding-bottom: 9px;
}

.product-category .card .circle {
    width: 60px;
    height: 56px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.product-category .card .circle .image-holder {
    position: relative;
    width: 100%;
    height: 100%;
}

.product-category .card .circle .image-holder .tringle-img {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -50%);
    z-index: 0;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px 0px 30px 30px;
}

.product-category .card .circle .image-holder .arrow-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: transform 0.3s ease;
}

.product-category .card .circle .image-holder .arrow-icon .arrow-img {
    width: 12px;
    height: 12px;
    transition: transform 0.3s ease;
}

.product-category .card:hover .circle {
    background-color: white;
    bottom: 0px;
}

.product-category .card:hover .circle .image-holder .arrow-icon {
    transform: translate(-44%, -93%);
}

.product-category .card:hover .arrow-icon .arrow-img {
    transform: translateX(15px);
}

.download-box {
    background: #CCCCCC 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 15px 15px;
    padding: 10px;
    width: 80%;
    position: absolute;
    left: 50%;
    bottom: -13px;
    transform: translateX(-49%);
    transition: bottom 0.3s ease, height 0.3s ease;
    z-index: -1;
    /* Ensure the download box is above the card and card body */
}

.product-category .card:hover .download-box {
    bottom: -42px;
    height: auto;
    /* Ensure the download box expands to fit its content */
}

.download-box p {
    margin-bottom: 0%;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.download-box:hover p {
    color: #ED6115;
}

.product-page-card .card .circle .image-holder .tringle-img {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -50%);
    z-index: 0;
    background: #FFF;
    border-radius: 30px 0px 30px 30px;
}

.product-page-card .card .circle .image-holder .arrow-icon .arrow-img {
    filter: brightness(0%);
}

.product-page-card .card:hover .circle .image-holder .tringle-img {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -50%);
    z-index: 0;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 30px 0px 30px 30px;
}

.product-page-card .card:hover .circle .arrow-icon .arrow-img {
    transform: translateX(15px);
    filter: brightness(0) invert(1);
}

/* .product-page-card .card {
    background: linear-gradient(180deg, rgba(219, 222, 221, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(219, 222, 221, 1) 100%);
} */

.product-category .card span {
    font-size: 12px;
    color: #FFF;
    margin-bottom: 0%;
}