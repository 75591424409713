.product-enquiry-form {
    margin: 5% 0;
}

.product-enquiry-form .phone-input-container {
    display: flex !important;
    align-items: center !important;
}

.product-enquiry-form .phone-input-button {
    flex-shrink: 0 !important;
    margin-right: 10px !important;

}

.product-enquiry-form .phone-input-field {
    flex-grow: 1 !important;
}

.product-enquiry-form .react-tel-input .form-control {
    font-size: 16px;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    border-radius: 5px;
    width: 100%;
    outline: none;
    padding: 7.5px 16px 9.5px 60px !important;
    transition: box-shadow ease .25s, border-color ease .25s;
    color: #495057;
}

.product-enquiry-form .form-control {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.product-enquiry-form .form-control:focus {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.product-enquiry-form .form-select {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.product-enquiry-form .textarea-class {
    height: auto !important;
}

.product-enquiry-form .form-select:focus {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    height: 40px;
}

.product-enquiry-form .form-control::placeholder {
    color: #707070;
    font-size: 14px;

}

.product-enquiry-form .react-select-container {
    margin-bottom: 1rem;
}

.product-enquiry-form .react-select__control {
    height: 38px;
}

.product-enquiry-form .react-select__placeholder {
    color: #6c757d;

}

.product-enquiry-form .form-label {
    font-size: 15px;
    font-weight: 500;
}

.product-enquiry-form .btn-submit {
    color: #FFF;
    background-color: black;
    font-size: 14px;
    /* height: 38px; */
    border-radius: 5px;
}

.product-enquiry-form .btn-submit:hover {
    color: #FFF;
    background: #ED6115 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font-size: 14px;
    /* height: 38px; */
}

.product-enquiry-form .social-icon {
    float: right;
}

.product-enquiry-form .social-icon .border-icon {
    margin-right: 5px;
}

.product-enquiry-form .social-icon .sociallogo {
    width: 22px;
    height: 22px;
}

.product-enquiry-form .Share {
    font-size: 10px;

}

.product-enquiry-form .product-img {
    border-radius: 50% 0% 50% 50%;
    width: 100%;
    aspect-ratio: 1/0.9;
}

.product-enquiry-form .image-holder {
    position: relative;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 50% 0% 50% 50%;
    padding: 0px 0px 0px 8px;

}

.product-enquiry-form .image-holder .search-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

.product-enquiry-form .download-box {
    display: block;
}

.product-enquiry-form .form-section h2 {
    font-size: 23px;
    font-weight: 600;
}

.product-enquiry-form .bg-image-color {
    background: linear-gradient(180deg, rgba(219, 222, 221, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(219, 222, 221, 1) 100%);
    border-radius: 50% 0% 50% 50%;
    width: 100%;
    aspect-ratio: 1 / 0.9;
}

.pro-enuiry-card {
    margin-bottom: 65px;
}

@media(min-width:0px) and (max-width:767px) {
    .product-enquiry-form .form-section h2 {
        font-size: 20px;
        text-align: center;

    }

    .product-enquiry-form .btn-submit {
        font-size: 12px;


    }

    .product-enquiry-form .btn-submit:hover {
        font-size: 12px;

    }

    .pro-enuiry-card {
        margin-bottom: 65px;
    }
}