.button {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: 0.3s;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #ED6115;
    border-radius: 27px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.button:hover {
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #E31E24;
    border-radius: 27px;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
}

/* BUTTON UP */
.label-up {
    display: block;
    margin: 0px 44px;
    height: 100%;
    position: relative;
    top: 0%;
    transition: 0.3s;
}

.button:hover .label-up {
    top: -100%;
}



@media(min-width:0px) and (max-width:767px) {
    .button {

        font-size: 12px;

    }

    .button:hover {
        font-size: 12px;

    }
}

@media(min-width:768px) and (max-width:992px) {
    .label-up {
        margin: 0px 30px;
    }

    .button {

        font-size: 14px;

    }

    .button:hover {
        font-size: 14px;

    }
}

@media(min-width:992px) and (max-width:1200px) {}