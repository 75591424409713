.welcome-page {
    width: 100%;
    height: 100%;
}

.welcome-page .video-main .video-bg {
    width: 100%;
    height: 90vh;
    min-height: 94vh;
    object-fit: contain;
    margin-bottom: -7px;
    border: none !important;
}