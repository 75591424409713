.product-banner {
    background: #F9F9F9;
}

.product-banner .border-category {
    background-image: url('../../../../public/assests/images/icon/Subtraction.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: #ED6115;
    border-radius: 190px 2px 8px 7px; */
    height: 100%;
    /* width: 186px; */

}

.product-banner .border-category h3 {
    color: #FFF;
    font-size: 30px;
    text-align: center;
    font-family: 'Montserrat';
    margin-left: 51px;
    margin-top: 51px;
}

.product-banner .textholder {
    margin: 20px 0px;
}

.product-banner .textholder h2 {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Montserrat';
    margin-top: 30px;

}

.product-banner .textholder p {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat';
    margin-bottom: 0%;
}

.product-category-banner .product-banner .border-category h3 {
    margin-left: 142px;

}

@media(min-width:0px) and (max-width:767px) {
    .product-banner .border-category h3 {
        font-size: 18px;

    }

    .product-banner .textholder h2 {
        font-size: 18px;
    }

    .product-banner .textholder p {
        font-size: 11px;

    }

    .product-category-banner .product-banner .border-category h3 {
        margin-left: 130px;

    }
}