.product-not-found {
    margin: 5% 0;
}

.product-not-found .not-found-img .no-image {
    width: 352px;
    height: 285px;
    object-fit: contain;

}

.product-not-found .text-found p {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

@media(min-width:0px) and (max-width:486px) {
    .product-not-found .not-found-img .no-image {
        width: 100%;
        height: 285px;

    }
}

@media(min-width:0px) and (max-width:992px) {
    .product-not-found .text-found p {
        font-size: 25px;
    }
}