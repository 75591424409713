.Discove-Gula {
    margin-top: 6%;
    margin-bottom: 5%;
    background: transparent linear-gradient(0deg, #F8F8F8 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    padding-bottom: 50px;
}

.Discove-Gula .card-section {
    margin-top: 11%;
}

.Discove-Gula .heading-holder h3 {
    font-size: 18px;
    font-weight: bold;
    color: #E31E24;
    font-family: "Montserrat";
}



.Discove-Gula .heading-holder p {
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
}

.Discove-Gula .discover-img {
    position: relative;
}

.Discove-Gula .discover-img .image-holder .discover-gula {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    aspect-ratio: 1 / 1;
}

.Discove-Gula .discover-img .current-hover {
    position: absolute;
    background: transparent linear-gradient(180deg, #F8AA0500 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: hard-light;
    border-radius: 0px 0px 13px 13px;
    opacity: 0.5;
    width: 100%;
    height: 40%;
    bottom: 0px;
}

.Discove-Gula .details-holder {
    position: relative;
}

.Discove-Gula .details-holder .view-details {
    position: absolute;
    bottom: 0;
    left: 0;
}

.Discove-Gula .image-trusted {
    position: relative;
    height: 450px;
    /* overflow: hidden; */

}

.Discove-Gula .image-trusted .trusted-img {
    width: 100%;
    aspect-ratio: 1 / 0.6;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;

}

.Discove-Gula .card-section:hover .trusted-img {
    /* transform: scale(0.9); */
    /* padding-bottom: 5%; */
    width: 95%;

}

.Discove-Gula .image-trusted .second-img {
    position: absolute;
    top: 19px;
    right: 0px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
}

.Discove-Gula .image-trusted .second-img .trusted-img2 {
    width: 100%;
    border-left: 10px solid #fff;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
    border-radius: 25px;
    height: 282px;
    transition: transform 0.3s ease-in-out;
}

.Discove-Gula .card-section:hover .second-img {
    top: 40px;
}

.Discove-Gula .card-section:hover .second-img .trusted-img2 {
    transform: scale(1.3);
}

.Discove-Gula .text-name h3 {
    color: #B2B2B2;
    font-size: 53px;
    font-weight: bold;
    transition: opacity 0.3s ease-in-out;
    margin-top: 40px;
}

.Discove-Gula .card-section:hover .text-name h3 {
    color: #FFC8CA;

}

@media(min-width:0px) and (max-width:767px) {
    .Discove-Gula .details-holder {
        position: unset;
    }

    .Discove-Gula .details-holder .view-details {
        position: unset;

    }

    .Discove-Gula .heading-holder h2 {
        font-size: 18px;
    }

    .Discove-Gula .heading-holder p {
        font-size: 13px;
    }

    .Discove-Gula .heading-holder h3 {
        font-size: 14px;
    }

    .Discove-Gula .discover-img .image-holder {
        margin-top: 10px;
        text-align: center;
    }

    .Discove-Gula .image-trusted {
        height: auto;
    }

    .Discove-Gula .text-name h3 {
        font-size: 30px;
        margin-top: 20px;
    }

    .Discove-Gula .image-trusted .second-img .trusted-img2 {
        height: 160px;
        display: none;

    }

    .Discove-Gula .image-trusted .second-img {
        top: 19px;
        right: 20px;

    }

    .Discove-Gula .card-section:hover .second-img {
        top: 19px;
        right: 30px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .Discove-Gula .heading-holder h2 {
        font-size: 18px;
    }

    .Discove-Gula .heading-holder p {
        font-size: 13px;
    }

    .Discove-Gula .heading-holder h3 {
        font-size: 14px;
    }

    .Discove-Gula .details-holder .view-details {
        position: unset;

    }

    .Discove-Gula .card-section:hover .second-img {
        top: 32px;
        right: 18px;
    }

    .Discove-Gula .image-trusted {
        height: auto;
    }

    .Discove-Gula .text-name h3 {
        font-size: 30px;
        margin-top: 20px;
    }

    .Discove-Gula .image-trusted .second-img .trusted-img2 {
        height: 214px;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Discove-Gula .heading-holder h2 {
        font-size: 26px;
    }

    .Discove-Gula .heading-holder p {
        font-size: 15px;
    }

    .Discove-Gula .image-trusted .text-name h3 {
        font-size: 35px;
    }

    .Discove-Gula .image-trusted .second-img .trusted-img2 {
        height: 258px;
    }

    .Discove-Gula .image-trusted .second-img {
        top: 8px;

    }
}