.About-Us-Sec {
    margin-top: 5% ;
    margin-bottom: 8%;
}

.About-Us-Sec .about-col {
    position: relative;
}

.About-Us-Sec .about-col .view-details {
    position: absolute;
    bottom: 0;
    left: 0;
}

.About-Us-Sec .image-holder {
    position: relative;
}

.About-Us-Sec .image-holder .about-image {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: 100%;
    border-radius: 50% 50% 50% 0px;
}

.About-Us-Sec .image-holder .circle-holder {
    position: absolute;
    border-radius: 88px 0px 88px 88px;
    top: 67px;
    left: 38%;
    z-index: 0;
    width: 180px;
    height: 180px;
    background: transparent linear-gradient(180deg, #f8aa053b 0%, #e31e2433 100%) 0% 0% no-repeat padding-box;
}

.About-Us-Sec .orange-circle {
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    width: 76px;
    height: 76px;
    border-radius: 38px 38px 0px 38px;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

@media(min-width:0px) and (max-width:767px) {
    .About-Us-Sec .about-col {
        position: unset;
    }

    .About-Us-Sec .about-col .view-details {
        position: unset;

    }

    .About-Us-Sec .orange-circle {
        position: unset;
        display: none;
    }

    .About-Us-Sec .image-holder .circle-holder {
        width: 130px;
        height: 130px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .About-Us-Sec .about-col {
        position: unset;
    }

    .About-Us-Sec .about-col .view-details {
        position: unset;

    }

    .About-Us-Sec .orange-circle {
        width: 50px;
        height: 50px;
        border-radius: 38px 38px 0px 38px;
        position: absolute;
        bottom: 242px;
        left: 50%;
        right: 50%;
    }

    .About-Us-Sec .image-holder .circle-holder {
        width: 130px;
        height: 130px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .About-Us-Sec .about-col .view-details {
        bottom: -38px;

    }

    .About-Us-Sec {
        margin: 7% 0;
    }

    .About-Us-Sec .orange-circle {
        width: 50px;
        height: 50px;
        border-radius: 38px 38px 0px 38px;
        position: absolute;
        bottom: 23px;
        left: 50%;
        right: 50%;
    }
}