.privacy-policy {
    margin-top: 2%;
    margin-bottom: 5%;
}

.privacy-policy .box-color {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 20px 30px 20px 30px;
}

.privacy-policy .text-holder h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0px;
}

.privacy-policy .text-holder p {
    font-size: 15px;
}