.global-reach {
    margin: 5% 0;

}

.global-reach .heading-holder p {
    font-size: 16px;
    font-weight: 500;
}

.global-reach .video-class-grid {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.global-reach .main-box {
    position: relative;
}

.global-reach .main-box .autowalaimgdiv {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    width: 650px;
}

.global-reach .mobileotowala {
    width: 100%;

 
}

@media(min-width:0px) and (max-width:767px) {
    .global-reach .main-box {
        position: unset;
    }

    .global-reach .main-box .autowalaimgdiv {
        position: unset;
        width: 100%;
    }

    .global-reach .video-class-grid {
        width: 100%;
        height: auto;
        object-fit: cover;

    }
}

@media(min-width:768px) and (max-width:992px) {
    .global-reach .main-box .autowalaimgdiv {
        width: 397px;
    }

    .global-reach .mobileotowala {
        margin-top: 37px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .global-reach .main-box .autowalaimgdiv {
        width: 500px;
    }

    .global-reach .mobileotowala {
        margin-top: 37px;
    }
}