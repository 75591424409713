.Comman-banner {
    border-radius: 0px 0px 50px 50px;
    border-radius: 0px 0px 50px 50px;
    /* padding: 123px 0px;
    object-fit: cover; */
    position: relative;
}

.Comman-banner .banner-img {
    width: 100%;
}

.Comman-banner .heading-holder {
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
}

.Comman-banner .heading-holder h1 {
    font-size: 40px;
    font-weight: bold;
    font-family: "Montserrat";
    color: #FFF;
}

@media(min-width:0px) and (max-width:420px) {
    .Comman-banner .banner-img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1/0.3;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .Comman-banner .heading-holder h1 {
        font-size: 16px;

    }

    .Comman-banner .heading-holder {
        left: 27%;

    }


}

@media(min-width:768px) and (max-width:992px) {
    .Comman-banner .heading-holder h1 {
        font-size: 30px;

    }


}