.our-product {
    margin: 5% 0;
}

.our-product .download-box {
    display: block;
}

.our-product a {
    color: #000;
}