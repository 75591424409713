.honor-award {
    margin: 5% 0;
}

.honor-award .swiper {
    padding-top: 50px;
    padding-bottom: 50px;
}

.honor-award .award-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
    border-radius: 15px;

}
.honor-award .first-image{
    padding: 45px 15px ;
}
.honor-award .first-image .logo-award {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/0.6;
}