.they-trust {
    margin: 5% 0;
}



.they-trust .client-card-box {
    background: #FBFBFB 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 15px;
    padding: 20px;
    min-height: 239px;
}

.they-trust .client-card-box .image-holder .client-img {
    border-radius: 155px 155px 0px 155px;
    width: 100%;
    margin-left: 5px;
    aspect-ratio: 1/1;

}

.they-trust .client-card-box .name-holder {
    margin-top: 50px;
}

.they-trust .client-card-box .name-holder h2 {
    font-size: 18px;
    font-weight: bold;
}

.they-trust .client-card-box .name-holder p {
    font-size: 13px;
    font-weight: 500;
}

.they-trust .client-card-box .contect-text p {
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5;
    line-clamp: 5;
}

.they-trust .silder-btn {
    position: relative;
}

.they-trust .silder-btn .back-btn {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    z-index: 2;
    font-size: 25px;
    width: 50px;
    height: 38px;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 27px 0px 0px 27px;
    color: #fff;
    text-align: center;
    transition: color 0.2s ease, background 0.2s ease;
}

.they-trust .silder-btn .next-btn {
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    z-index: 2;
    font-size: 25px;
    width: 50px;
    height: 38px;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 27px 27px 0px;
    color: #fff;
    text-align: center;
    transition: color 0.2s ease, background 0.2s ease;
}

.they-trust .silder-btn .back-btn {
    bottom: 29px;
    right: 58px;
}

.they-trust .silder-btn .next-btn {
    right: 0%;
    bottom: 29px;

}

.they-trust .swiper-button-prev.swiper-button-disabled,
.they-trust .swiper-button-next.swiper-button-disabled {
    display: none;
}

.they-trust .button:hover {
    background: transparent;
    border: none;
    border-radius: 0;
    color: #FFF;
    font-size: 20px;
}

.they-trust .button {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: transparent;
    color: white;
    transition: 0.3s;
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 20px;


}

.they-trust .label-up {
    display: block;
    margin: 0px 0px;
    height: 100%;
    position: relative;
    top: 0%;
    transition: 0.3s;
}

.they-trust .client-card-box .image-holder {
    padding: 0px 0px;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 100px 100px 0px 100px;
}

@media(min-width:0px) and (max-width:767px) {
    .they-trust .client-card-box .image-holder .client-img {
        width: 152px;
        height: 152px;

    }

    .they-trust .client-card-box .image-holder {
        width: 152px;
        height: 152px;
    }

    .they-trust .client-card-box .contect-text p {
        font-size: 14px;
        margin-top: 10px;
    }

    .they-trust .client-card-box .name-holder {
        margin-top: 15px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    /* .they-trust .client-card-box .image-holder .client-img {
        width: 152px;
        height: 152px;

    }

    .they-trust .client-card-box .image-holder {
        width: 152px;
        height: 152px;
    } */

    .they-trust .client-card-box .contect-text p {
        font-size: 13px;

    }

    .they-trust .client-card-box .name-holder {
        margin-top: 15px;
    }
}