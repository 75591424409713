@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


body {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', 'sans-serif' !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.heading-holder h2 {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: bold;
}

.heading-holder p {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}

.mySwiper .swiper-pagination-bullet {
  background: #ED6115;
  opacity: 1;
  height: 3px;
  border-radius: 50px;
}

.mySwiper .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ED6115;
  border-radius: 50px;
  width: 34px;
  height: 3px;
}



@media(min-width:0px) and (max-width:767px) {
  .heading-holder h2 {
    font-size: 18px;
  }

  .heading-holder p {
    font-size: 13px;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .heading-holder h2 {
    font-size: 18px;
  }

  .heading-holder p {
    font-size: 13px;
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .heading-holder h2 {
    font-size: 26px;
  }

  .heading-holder p {
    font-size: 14px;
  }
}