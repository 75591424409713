.card-manufacturing {
    background: transparent linear-gradient(180deg, #EEEEEEB3 0%, #EEEEEE 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    padding: 26px 15px 5px 15px;
    height: 90%;
    margin-bottom: 29px;
    position: relative;
    overflow: hidden;
    min-height: 150px;
}

.card-manufacturing .card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background: transparent linear-gradient(180deg, #F8AA05 0%, #E31E24 100%) 0% 0% no-repeat padding-box;
    border-radius: 15px;
    width: 100%;
    height: 0;
    transition: height 0.3s ease-in-out;
    z-index: 1;

}

.card-manufacturing:hover .card-overlay {
    height: 100%;
    /* Expand overlay on hover */
}

.card-manufacturing .text-holder p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    z-index: 2;
    /* transition: color 0.3s ease-in-out; */
    margin-top: 10px;
}

.card-manufacturing:hover .text-holder p {
    color: #FFF;

}

.card-manufacturing .image-icon .icon-img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    position: relative;
    z-index: 2;
    transition: filter 0.3s ease-in-out;

}

.card-manufacturing:hover .image-icon .icon-img {
    filter: brightness(0) invert(1);
    transform: translateY(-5px);

}