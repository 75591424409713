.client-card {
    background: #ed61155e 0% 0% no-repeat padding-box;
    border-radius: 15px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* padding: 16px 20px; */
    transition: background 0.3s ease-in-out;
    overflow: hidden;
    /* Ensure content doesn't overflow */
}

.client-card .ttt-sec {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.client-card .image-holder {
    padding: 16px 20px;
}

.client-card .image-holder .icon-img {
    width: 65px;
    height: 65px;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
}

.client-card:hover .image-holder .icon-img {
    transform: scale(1.1);

}

.client-card .text-holder {
    flex-grow: 1;
    text-align: center;


}

.client-card .text-holder p {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

.client-card .text-holder-counter {
    position: relative;
    padding: 10px;
    border-radius: 15px;
    overflow: hidden;
    background: linear-gradient(to right, #ED6115 50%, transparent 50%);
    background-size: 201% 100%;
    background-position: right bottom;
    transition: background-position 0.3s ease-in-out;
    height: 100%;
    text-align: center;
}

.client-card:hover .text-holder-counter {
    background-position: left bottom;
}

.client-card .text-holder-counter h4 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    color: #000;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease-in-out;
    margin-top: 19px;
}

.client-card:hover .text-holder-counter h4 {
    color: #fff;
}

@media(min-width:0px) and (max-width:767px) {
    .client-card .text-holder-counter h4 {
        font-size: 25px;
    }

    .client-card .text-holder p {
        font-size: 17px;

    }
}