.home-banner .video-class-grid {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 0px 0px 60px;
}

@media(min-width:0px) and (max-width:767px) {
    .home-banner .video-class-grid {
        border-radius: 0px 0px 0px 20px;
    }
}